var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "regression" }, [
    _c("div", { staticClass: "regression_warp" }, [
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "footer" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "router-link",
              {
                staticClass: "immediately",
                attrs: {
                  to: "/" + this.$route.params.lang + "/SignupImmediately"
                }
              },
              [_vm._v("立即报名")]
            ),
            _c("div", { staticClass: "Return", on: { click: _vm.Return } }, [
              _vm._v("\n                    返回\n                ")
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("h2", [_vm._v("2019康马品牌国际开放日")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "introduce" }, [
      _c("p", { staticClass: "title_text" }, [
        _vm._v(
          "据赛克思液压国内销售负责人胡红女士介绍，2019康马展期间，有许多来自印度、马来西亚、孟佳拉国、泰国、台湾等国家和地区的国际买家前来咨询洽谈。"
        )
      ]),
      _c("div", { staticClass: "IMGurl" }, [
        _c("img", {
          attrs: { src: "/images/Summit/img_wangjie_2@2x.png", alt: "" }
        })
      ]),
      _c("p", { staticClass: "qiatan" }, [
        _vm._v("孟家拉国的客商与赛克液压的工作人员针对代理事宜进行洽.谈")
      ]),
      _c("div", { staticClass: "text_Warp" }, [
        _c("p", [
          _vm._v(
            "目前，赛克思已经在国内外设立50多个经销网点，销售网络覆盖全国并延伸全球，部分产品早已进入欧美市场，并得到客户的高度认可。"
          )
        ]),
        _c("p", [
          _vm._v(
            " 胡红表示在赛克思内部早已建立独具优势的制造体系，具备从精密铸造、精密加工、热处理、双金属烧结、组装到测试全部柱塞泵、马达的整套制作工艺及装备，并拥有众多国际先进的全自动机械加工和液压元件测试设备。"
          )
        ])
      ]),
      _c("div", { staticClass: "ConsultationList" }, [
        _c("ul", [
          _c("li", [
            _c("img", {
              attrs: { src: "/images/Summit/img_wangjie_3@2x.png", alt: "" }
            })
          ]),
          _c("li", [
            _c("img", {
              attrs: { src: "/images/Summit/img_wangjie_4@2x.png", alt: "" }
            })
          ]),
          _c("li", [
            _c("img", {
              attrs: { src: "/images/Summit/img_wangjie_5@2x.png", alt: "" }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "text_Warp" }, [
        _c("p", [
          _vm._v(
            "目前，赛克思已经在国内外设立50多个经销网点，销售网络覆盖全国并延伸全球，部分产品早已进入欧美市场，并得到客户的高度认可。"
          )
        ]),
        _c("p", [
          _vm._v(
            " 胡红表示在赛克思内部早已建立独具优势的制造体系，具备从精密铸造、精密加工、热处理、双金属烧结、组装到测试全部柱塞泵、马达的整套制作工艺及装备，并拥有众多国际先进的全自动机械加工和液压元件测试设备。"
          )
        ])
      ]),
      _c("div", { staticClass: "text_Warp title_bo" }, [
        _c("p", [
          _vm._v(
            "目前，赛克思已经在国内外设立50多个经销网点，销售网络覆盖全国并延伸全球，部分产品早已进入欧美市场，并得到客户的高度认可。"
          )
        ]),
        _c("p", [
          _vm._v(
            " 胡红表示在赛克思内部早已建立独具优势的制造体系，具备从精密铸造、精密加工、热处理、双金属烧结、组装到测试全部柱塞泵、马达的整套制作工艺及装备，并拥有众多国际先进的全自动机械加工和液压元件测试设备。"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "Shareto" }, [_vm._v("分享至：")]),
      _c("ul", [
        _c("li", { staticClass: "weixing" }, [
          _c("img", {
            attrs: { src: "/images/Summit/icon_weixing@2x.png", alt: "" }
          }),
          _c("div", { staticClass: "QRcode" }, [
            _c("img", {
              attrs: { src: "/images/Summit/img_erweima@2x.png", alt: "" }
            })
          ])
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: "/images/Summit/icon_xinlang@2x.png", alt: "" }
          })
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: "/images/Summit/icon_in@2x.png", alt: "" }
          })
        ]),
        _c("li", [
          _c("img", { attrs: { src: "/images/Summit/icon_f@2x.png", alt: "" } })
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: "/images/Summit/icon_gezi@2x.png", alt: "" }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }