var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Summit" }, [
    _c("div", { staticClass: "Summit_containt" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "Summit_main" },
        [
          _c(
            "app-swiper",
            { staticClass: "warplist", attrs: { enablePagination: true } },
            _vm._l(_vm.review, function(item) {
              return _c("app-swiper-item", { key: item.id }, [
                _c("div", { staticClass: "left", style: _vm.bgimg }, [
                  _c("div", { staticClass: "top_left" }, [
                    _c("h3", [_vm._v("2019康马展")]),
                    _c("h4", [_vm._v("品牌国际开放日")]),
                    _c("span")
                  ]),
                  _c("div", { staticClass: "time" }, [
                    _c("h3", [_vm._v("时间：")]),
                    _c("p", [_vm._v(_vm._s(item.time))])
                  ]),
                  _c("div", { staticClass: "place" }, [
                    _c("h3", [_vm._v("地点：")]),
                    _c("p", [_vm._v(_vm._s(item.place))])
                  ]),
                  _c("div", { staticClass: "Sponsorunit" }, [
                    _c("h3", [_vm._v("主办单位：")]),
                    _c("p", [_vm._v(_vm._s(item.Sponsorunit))])
                  ]),
                  _c("div", { staticClass: "BrightSpot" }, [
                    _c("h3", [_vm._v("亮点：")]),
                    _c("p", [_vm._v(_vm._s(item.BrightSpot))])
                  ])
                ]),
                _c("div", { staticClass: "right" }, [
                  _c("img", { attrs: { src: item.imgUrl, alt: "" } })
                ])
              ])
            }),
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "Professional" }, [
      _c(
        "ul",
        { staticClass: "special_Warp" },
        _vm._l(_vm.special, function(item) {
          return _c("li", { key: item.id }, [
            _c("div", { staticClass: "numbers" }, [
              _vm._v(_vm._s(item.numbers))
            ]),
            _c("div", { staticClass: "texts" }, [_vm._v(_vm._s(item.texts))])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [_c("h3", [_vm._v("高峰会议")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }